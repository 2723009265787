<template>
  <v-container
    fill-height>
    <v-layout
       row 
       wrap 
       align-center>
       <v-layout
          row
          justify-center>
          <v-card
            :class="this.$vuetify.breakpoint.smAndUp ? 'login_container':'login_container_mobile'">
            <v-container
              fill-height>
              <v-layout
                column
                align-center>
                <v-card
                  flat
                  class="login_card_wrapper">
                  <v-container>
                      <v-layout
                        row
                        align-center>
                          <v-text-field 
                            v-model="user.sEmail"
                            dense
                            outlined
                            color="guest_primary"
                            label="Email"
                          />
                      </v-layout>
                      <v-layout
                        row
                        align-center>
                          <v-text-field 
                            v-model="user.sPassword"
                            :append-icon="loginPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="() => (loginPasswordVisible = !loginPasswordVisible)"
                            :type="loginPasswordVisible ? 'text' : 'password'"
                            dense
                            outlined
                            color="guest_primary"
                            label="Contraseña"
                          />
                      </v-layout>
                      <v-layout
                        row
                        justify-center
                        align-center>
                        <v-btn
                          large
                          color="secondary"
                          @click="set_login"
                          :loading="load_btn">
                          <p
                            class="btn_login_text"
                            v-html="btn_text"
                          />
                        </v-btn>
                      </v-layout>
                  </v-container>                  
                </v-card>
              </v-layout>
            </v-container>
          </v-card>
       </v-layout>
    </v-layout>  
  </v-container>
</template>
<script>
export default {
  data: () => ({
    loginPasswordVisible: false,
    btn_text: 'Iniciar Sesión',
    load_btn: false,
    user: {
      sPassword: '',
      sEmail: ''
    }
  }),
  methods: {
    set_login () {
      if (this.user.sPassword && this.user.sEmail) {
        this.login()
      } else {
        this.$store.commit('toggle_alert', {color: 'fail', text: 'Favor de llenar todos los campos.'})
      }
    },  
    reset_user () {
      this.user = {
        sPassword: '',
        sEmail: ''
      }
    },
    // login function manages states
    login: function () {
      this.load_btn = true
      this.$store.dispatch('login', this.user)
      .then((resp) => {
        this.load_btn = false
        this.reset_user()
        this.$router.push('/admin/projects')
        this.$store.commit('toggle_alert', {color: 'success', text: 'Bienvenido a Martel - Administración' })//resp.data.message})
      })
      .catch(err => {
        this.load_btn = false
        this.$store.commit('toggle_alert', {color: 'fail', text: err.response.data.message})
      })
    }
  }
}
</script>
<style scoped>
.btn_login_text {
  letter-spacing: .5px;
  text-transform: none;
  margin: 20px;
  color: white;
  font-size: 12px;
  font-family: 'Open sans', sans-serif;
  margin: auto
}
.login_card_wrapper {
  background-color: rgba(255, 255, 255, 0);
  margin-top: 16%;
  max-width: 80%;
  width: 90%;
}
.login_container {
  background-color: rgba(255, 255, 255, .9);
  max-width: 50%;
  max-height: 55vh;
  height: 100vh;
  width: 100vh
}
.login_container_mobile {
  background-color: rgba(255, 255, 255, .9);
  max-width: 90%;
  margin-left: 5%;
  max-height: 55vh;
  height: 100vh;
  width: 100vh
}
</style>